import { plainToClass } from 'class-transformer';
import { UserExperience } from "./expertise.objects";


export class ExpertisePage {

    constructor(userData: any) {
        $(() => {

            let existingExpertise: UserExperience = plainToClass(UserExperience, <UserExperience>userData);

            //console.log(existingExpertise);

            existingExpertise.setupUI();

        });
    }

    updateExpertise(): void
    {
        let updateData: UserExperience = new UserExperience();
        updateData.initialize();

        var actionUrl = $("#expertise-form").attr('action');

        var formData = $("#expertise-form").serializeArray();
        formData.push({ name: "payload", value: JSON.stringify(updateData)  });
        //console.log(formData);

        $.ajax({
            url: actionUrl,
            dataType: 'JSON',
            method: 'POST',
            data: formData,
            success: (data) => {}
        });

        $("html, body").animate({
            scrollTop: 0
        }, 600);
    }
}