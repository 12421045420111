import { DocumentTree } from './document-tree';


export class DocumentTreeLoader {

    documentTree: DocumentTree;

    constructor(nodes: any) {
        $(() => {

            this.documentTree = new DocumentTree(nodes);
        });
    }
}