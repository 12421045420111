import 'bootstrap';
import 'bootstrap-select';

import 'leaflet';
import 'leaflet-svg-shape-markers/dist/leaflet-svg-shape-markers.min.js';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';

import 'jstree';


import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-rowgroup';
import 'datatables.net-rowgroup-bs4';
import 'datatables.net-fixedcolumns';
import 'datatables.net-fixedcolumns-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons-bs4';
import 'datatables.net-scroller';




// SCRIPTS
import './scripts/font-awesome';
import './components/carousel/carousel';
import './components/scroll-to-top/scroll-to-top';

import { DocumentTreeLoader } from './components/document-tree/document-tree-loader'; export { DocumentTreeLoader };





// STYLES
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';

import 'et-line/style.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'jstree/dist/themes/default/style.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css';
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css';


import './styles/_base.scss';
import './styles/variables.scss';
import './styles/shared.scss';

import './styles/elements/nav-bar.scss';
import './styles/elements/footer.scss';
import './styles/elements/headings.scss';
import './styles/elements/button.scss';
import './styles/elements/data-table.scss';

import './styles/mixins/layout.scss';

import './components/carousel/carousel.scss';
import './components/scroll-to-top/scroll-to-top.scss';
import './components/document-tree/document-tree.scss';




// AUTH MODULE -- imported here to avoid having a separate auth.* bundle
//import { UserManagementPage } from '../auth/pages/management/scripts/management.page';
//export { UserManagementPage }; //to make discoverable as lib export

import { ExpertisePage } from '../auth/pages/profile/scripts/expertise.page'; export { ExpertisePage };

import '../auth/components/user-header/user-header.scss';
import '../auth/pages/auth.scss';
import '../auth/pages/profile/profile.scss';
//import '../auth/pages/management/management.scss';

