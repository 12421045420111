//Siema info: https://www.npmjs.com/package/siema

import Siema from 'siema';


// to account for IE/Edge rounding behaviour https://github.com/pawelgrzybek/siema/issues/244
Object.defineProperty(Siema.prototype, 'buildSliderFrameItem', {
    value: function (elm) {

        const elementContainer = document.createElement('div');

        elementContainer.style.cssFloat = this.config.rtl ? 'right' : 'left';
        elementContainer.style.float = this.config.rtl ? 'right' : 'left';
        elementContainer.style.width = `${this.selectorWidth / this.perPage}px`; // fix

        elementContainer.appendChild(elm);

        return elementContainer;
    }
});


$(document).ready(function () {
    //single carousel
    if ($('.siema.siema-one').length) {
        const carousel = new Siema({
            perPage: 1,
            loop: true
        });
        document.querySelector('.prev').addEventListener('click', () => carousel.prev(1, () => { $('#carouselActiveSlideLabel')?.text(carousel.currentSlide + 1); }));
        document.querySelector('.next').addEventListener('click', () => carousel.next(1, () => { $('#carouselActiveSlideLabel')?.text(carousel.currentSlide + 1); }));
    }

    //different init for the groups of 3
    //if ($('.siema.siema-three').length) {
    //    const carousel = new Siema({
    //        perPage: 3,
    //        loop: true
    //    });
    //    document.querySelector('.prev').addEventListener('click', () => carousel.prev(3));
    //    document.querySelector('.next').addEventListener('click', () => carousel.next(3));
    //}
});









