    
//only import what we use.
import { library, dom, IconDefinition } from "@fortawesome/fontawesome-svg-core";

import {
    faBars, faAngleLeft, faAngleRight, faAngleUp, faAngleDown, faTimes, faCaretSquareLeft, faEnvelope,
    faCog, faHome, faUser, faExternalLinkAlt,
    faFolder, faFileExcel, faFileWord, faFilePowerpoint, faFilePdf, faFile, faFileAlt, faFileAudio, faFileVideo, faFileDownload, faQuestionCircle,
    faCopy, faKey, faExclamation, faBullhorn, faFileCode, faFileImage,
    faDatabase, faServer, faCheck, faPlus, faCircleArrowRight, faArrowUpRightFromSquare,
    faVial, faCalendar, faArrowRight, faPlusCircle, faMagnifyingGlass, faSave, faSpinner, faTrash, faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

import { faTwitter, faLinkedin} from "@fortawesome/free-brands-svg-icons";

library.add(faBars); library.add(faAngleLeft); library.add(faAngleRight); library.add(faAngleUp); library.add(faAngleDown);
library.add(faTimes); library.add(faCaretSquareLeft); library.add(faEnvelope); library.add(faTwitter); library.add(faCog);
library.add(faHome); library.add(faUser); library.add(faExternalLinkAlt);
library.add(faFolder); library.add(faFileExcel); library.add(faFileWord); library.add(faFilePowerpoint); library.add(faFilePdf);
library.add(faFile); library.add(faFileDownload); library.add(faQuestionCircle); library.add(faCopy); library.add(faKey); library.add(faExclamation);
library.add(faBullhorn); library.add(); library.add(faBullhorn); library.add(faFileAlt); library.add(faFileAudio); library.add(faFileVideo); library.add(faFileCode);
library.add(faVial); library.add(faCalendar); library.add(faArrowRight); library.add(faFileImage); library.add(faMagnifyingGlass); library.add(faPlusCircle);
library.add(faVial); library.add(faCalendar); library.add(faArrowRight); library.add(faFileImage); library.add(faMagnifyingGlass); library.add(faDatabase); library.add(faServer);
library.add(faCheck); library.add(faPlus); library.add(faCircleArrowRight); library.add(faArrowUpRightFromSquare); library.add(faFileImage); library.add(faPlusCircle);
library.add(faSave); library.add(faSpinner); library.add(faTrash); library.add(faExclamationCircle); library.add(faLinkedin);

dom.watch();